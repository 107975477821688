var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"d2d2d999e99741a3140079f2f2831c72baa257c0"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from "@sentry/nextjs";
import { Integrations } from "@sentry/tracing";
import { NORMALIZE_DEPTH, SENTRY_DSN } from "@uplift-ltd/sentry";

// This is only working for server side errors, possibly due to a conflict with @uplift-ltd/sentry which
// pulls in the Node version only. As a workaround, we also have a SentryBrowserProvider for manually catching client errors
Sentry.init({
  dsn: SENTRY_DSN,
  normalizeDepth: NORMALIZE_DEPTH,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1, // log 100% of transactions
});

export {};
